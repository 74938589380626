<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth';

const { identifyUserOnMounted } = useAuthStore();

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Handle` : 'Handle';
  }
});

onMounted(() => {
  identifyUserOnMounted();
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
