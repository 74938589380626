import usePosthog from '@/composables/helpers/usePosthog';
import type { NuxtApp } from 'nuxt/app';

export default function useIdentification(externalNuxtApp?: NuxtApp) {
  const identifyUser = <P extends Record<string, any>>(
    idKey: string,
    payload: P
  ) => {
    try {
      const posthog = usePosthog(externalNuxtApp);

      if (posthog && !posthog._isIdentified()) {
        posthog.identify(idKey, payload);
      }
    } catch (_error: any) {
      console.log('Failed to identify user');
    }
  };

  const updateUserIdentification = <P extends Record<string, any>>(
    payload: P
  ) => {
    try {
      const posthog = usePosthog(externalNuxtApp);

      if (posthog && posthog._isIdentified()) {
        posthog.capture('$set', {
          $set: payload
        });
      }
    } catch (_error: any) {
      console.log('Failed to update user identification');
    }
  };

  const resetUserIdentification = () => {
    try {
      const posthog = usePosthog(externalNuxtApp);

      if (posthog && posthog._isIdentified()) {
        posthog.reset();
      }
    } catch (_error: any) {
      console.log('Failed to reset identification');
    }
  };

  return {
    identifyUser,
    updateUserIdentification,
    resetUserIdentification
  };
}
