import type { PostHog } from 'posthog-js';
import { type NuxtApp, tryUseNuxtApp } from 'nuxt/app';

export default function usePosthog(externalNuxtApp?: NuxtApp) {
  const nuxtApp = externalNuxtApp || tryUseNuxtApp();

  if (nuxtApp) {
    const { $clientPosthog, $serverPosthog } = nuxtApp;

    if (process.server) {
      return $serverPosthog as PostHog | null;
    }

    return $clientPosthog as PostHog | null;
  }

  return null;
}
