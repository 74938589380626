import { LEVEL_PROGRESS_STORE_KEY } from '@/stores/statistic/constants';
import {
  Timeframes,
  type LevelProgressPoint,
  type StatisticPageMeta,
  type StatisticParamsStrict
} from '@/types/statistic';
import { useLevelProgress } from '@/composables/api/actions/statistic';
import { useChartData } from '@/stores/statistic/helpers';

export const useLevelProgressStore = defineStore(
  LEVEL_PROGRESS_STORE_KEY,
  () => {
    const params = ref<StatisticParamsStrict>({
      timeframe: Timeframes.Month,
      unitOffset: 0
    });
    const data = ref<LevelProgressPoint[]>([]);
    const fourWeeksData = ref<LevelProgressPoint[]>([]);
    const meta = ref<StatisticPageMeta>({
      hasPreviousPage: false,
      hasNextPage: false,
      dateLabel: ''
    });

    const dataIsEmpty = computed<boolean>(() => {
      return !data.value?.length;
    });

    const fourWeeksDataIsEmpty = computed<boolean>(() => {
      return !fourWeeksData.value?.length;
    });

    async function getData() {
      const response = await useLevelProgress(params.value);

      if (response.data) {
        data.value = response.data.data;
        meta.value = response.data.meta;
      }

      return response;
    }

    async function getFourWeeksData() {
      const response = await useLevelProgress({ timeframe: Timeframes.Month });

      if (response.data) {
        fourWeeksData.value = response.data.data;
      }

      return response;
    }

    function resetStore() {
      params.value = {
        timeframe: Timeframes.Month,
        unitOffset: 0
      };
      data.value = [];
      fourWeeksData.value = [];
      meta.value = {
        hasPreviousPage: false,
        hasNextPage: false,
        dateLabel: ''
      };
    }

    const { getPageData, updateTimeframe, handleBack, handleForward } =
      useChartData(params, meta, getData);

    return {
      params,
      data,
      dataIsEmpty,
      meta,
      fourWeeksData,
      fourWeeksDataIsEmpty,
      getData,
      getFourWeeksData,
      getPageData,
      updateTimeframe,
      handleBack,
      handleForward,
      resetStore
    };
  }
);
