import { TRUMPS_WINS_STORE_KEY } from '@/stores/statistic/constants';
import {
  Timeframes,
  type StatisticPageMeta,
  type TrumpsWinsData,
  type StatisticParamsStrict
} from '@/types/statistic';
import { useTrumpsWins } from '@/composables/api/actions/statistic';
import { useChartData } from '@/stores/statistic/helpers';

export const useTrumpsWinsStore = defineStore(TRUMPS_WINS_STORE_KEY, () => {
  const params = ref<StatisticParamsStrict>({
    timeframe: Timeframes.Month,
    unitOffset: 0
  });
  const data = ref<TrumpsWinsData>({
    trumps: [],
    nt: []
  });
  const meta = ref<StatisticPageMeta>({
    hasPreviousPage: false,
    hasNextPage: false,
    dateLabel: ''
  });

  const dataIsEmpty = computed<boolean>(() => {
    return !data.value?.trumps?.length || !data.value?.nt?.length;
  });

  async function getData() {
    const response = await useTrumpsWins(params.value);

    if (response.data) {
      data.value = response.data.data;
      meta.value = response.data.meta;
    }

    return response;
  }

  function resetStore() {
    params.value = {
      timeframe: Timeframes.Month,
      unitOffset: 0
    };
    data.value = {
      trumps: [],
      nt: []
    };
    meta.value = {
      hasPreviousPage: false,
      hasNextPage: false,
      dateLabel: ''
    };
  }

  const { getPageData, updateTimeframe, handleBack, handleForward } =
    useChartData(params, meta, getData);

  return {
    params,
    data,
    dataIsEmpty,
    meta,
    getData,
    getPageData,
    updateTimeframe,
    handleBack,
    handleForward,
    resetStore
  };
});
