<script setup lang="ts">
import { cva } from 'class-variance-authority';

const attrs = useAttrs();

enum Sizes {
  Small = 'sm',
  Medium = 'md'
}

enum Colors {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
  Tertiary = 'tertiary'
}

enum StyleNames {
  Round = 'round',
  Square = 'square'
}

interface IconProps {
  size?: string;
  className?: string;
}

interface IconEvents {
  [id: string]: (...args: any[]) => void;
}

interface Props {
  text?: string;
  size?: Sizes;
  color?: Colors;
  block?: boolean;
  styleName?: StyleNames;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  startIcon?: string | boolean;
  startIconProps?: IconProps | boolean;
  startIconEvents?: IconEvents | boolean;
  endIcon?: string | boolean;
  endIconProps?: IconProps | boolean;
  endIconEvents?: IconEvents | boolean;
  as?: string | object;

  [x: string]: any;
}

const props = withDefaults(defineProps<Props>(), {
  size: Sizes.Medium,
  color: Colors.Primary,
  block: false,
  styleName: StyleNames.Round,
  startIconEvents: () => {
    return {};
  },
  endIconEvents: () => {
    return {};
  },
  as: 'button'
});

const component = computed(() => {
  if (attrs.to || attrs.href) {
    return defineNuxtLink({
      activeClass: undefined,
      exactActiveClass: undefined
    });
  }
  return props.as;
});

const buttonEllipseClass =
  'before:block before:absolute before:h-[29px] before:w-full before:rounded-[50%] hover:before:hidden';

const ellipseColorClasses: Partial<Record<Colors, string>> = {
  [Colors.Primary]: 'before:bg-brand-red-400',
  [Colors.Secondary]: 'before:bg-brand-navy-500'
};

const buttonClass = computed(() => {
  return cva(
    [
      'relative justify-center items-center px-5 overflow-hidden',
      props.className
    ],
    {
      variants: {
        size: {
          [Sizes.Small]: 'min-h-[38px] before:bottom-[-13px]',
          [Sizes.Medium]: 'min-h-11 before:top-[-6px]'
        },
        color: {
          [Colors.Primary]: [
            'bg-brand-red-500 hover:bg-brand-red-300',
            buttonEllipseClass,
            ellipseColorClasses[Colors.Primary]
          ],
          [Colors.Secondary]: [
            'bg-brand-navy-600 hover:bg-brand-navy-400',
            buttonEllipseClass,
            ellipseColorClasses[Colors.Secondary]
          ],
          [Colors.White]: 'bg-brand-white hover:bg-white',
          [Colors.Tertiary]:
            'bg-transparent border border-brand-navy-700 hover:bg-brand-white'
        },
        styleName: {
          [StyleNames.Round]: 'rounded-full',
          [StyleNames.Square]: 'rounded-[10px]'
        },
        block: {
          true: 'flex w-full',
          false: 'inline-flex w-fit'
        },
        disabled: {
          true: 'pointer-events-none before:hidden opacity-40'
        }
      },
      compoundVariants: [
        {
          color: Colors.Tertiary,
          disabled: true,
          class: '!bg-transparent'
        },
        {
          color: Colors.White,
          disabled: true,
          class: '!bg-brand-white !opacity-100'
        }
      ]
    }
  )({
    size: props.size,
    color: props.color,
    styleName: props.styleName,
    block: props.block,
    disabled: props.disabled
  });
});

const colorClass: Record<Colors, string> = {
  [Colors.Primary]: 'text-white',
  [Colors.Secondary]: 'text-white',
  [Colors.White]: 'text-brand-navy-700',
  [Colors.Tertiary]: 'text-brand-navy-700'
};

const textClass = computed(() => {
  return cva('relative', {
    variants: {
      size: {
        [Sizes.Small]: 'text-lg leading-6',
        [Sizes.Medium]: 'text-xl leading-[26px]'
      },
      loading: {
        true: 'invisible'
      },
      color: colorClass,
      disabled: {
        true: ''
      }
    },
    compoundVariants: [
      {
        color: Colors.White,
        disabled: true,
        class: 'opacity-60'
      }
    ]
  })({
    size: props.size,
    loading: props.loading,
    color: props.color,
    disabled: props.disabled
  });
});

const iconClass = cva('relative min-w-fit', {
  variants: {
    position: {
      start: ['-ml-1 mr-1.5', props.startIconProps?.className],
      end: ['-mr-1 ml-1.5', props.endIconProps?.className]
    },
    loading: {
      true: 'invisible'
    },
    color: colorClass,
    disabled: {
      true: props.color === String(Colors.White) && 'opacity-60'
    }
  }
});

const loaderClass = computed(() => {
  return cva('absolute', {
    variants: {
      color: colorClass
    }
  })({
    color: props.color
  });
});

const iconSizes: Record<Sizes, string> = {
  [Sizes.Small]: '26',
  [Sizes.Medium]: '26'
};

function iconSize(position: 'start' | 'end') {
  return props[`${position}IconProps`]?.size || iconSizes[props.size];
}
</script>

<template>
  <component :is="component" :class="buttonClass" :disabled="disabled">
    <Icon
      v-if="startIcon"
      :class="iconClass({ position: 'start', loading, color, disabled })"
      :name="startIcon"
      :size="iconSize('start')"
      v-on="startIconEvents"
    />
    <Loader
      v-if="loading"
      :class="loaderClass"
      :height="iconSizes[size]"
      :width="iconSizes[size]"
    />
    <span :class="textClass">
      <slot>{{ text }}</slot>
    </span>
    <Icon
      v-if="endIcon"
      :class="iconClass({ position: 'end', loading, color, disabled })"
      :name="endIcon"
      :size="iconSize('end')"
      v-on="endIconEvents"
    />
  </component>
</template>
