import { default as courses4kZe7KULJEMeta } from "/tmp/build_a488a427/pages/courses.vue?macro=true";
import { default as indext5Pm2WnOL1Meta } from "/tmp/build_a488a427/pages/index.vue?macro=true";
import { default as loginWLisoaJ9nMMeta } from "/tmp/build_a488a427/pages/login.vue?macro=true";
import { default as playT4uVvXgVeHMeta } from "/tmp/build_a488a427/pages/play.vue?macro=true";
import { default as handleFG7J2jHLz2Meta } from "/tmp/build_a488a427/pages/settings/handle.vue?macro=true";
import { default as index9Cm5iZpJ6nMeta } from "/tmp/build_a488a427/pages/settings/index.vue?macro=true";
import { default as sign_45upn7Hzt22HeEMeta } from "/tmp/build_a488a427/pages/sign-up.vue?macro=true";
import { default as statisticVxWuSB3L46Meta } from "/tmp/build_a488a427/pages/statistic.vue?macro=true";
import { default as tournamentEb0zpXjh4cMeta } from "/tmp/build_a488a427/pages/tournament.vue?macro=true";
export default [
  {
    name: courses4kZe7KULJEMeta?.name ?? "courses",
    path: courses4kZe7KULJEMeta?.path ?? "/courses",
    meta: courses4kZe7KULJEMeta || {},
    alias: courses4kZe7KULJEMeta?.alias || [],
    redirect: courses4kZe7KULJEMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: indext5Pm2WnOL1Meta?.name ?? "index",
    path: indext5Pm2WnOL1Meta?.path ?? "/",
    meta: indext5Pm2WnOL1Meta || {},
    alias: indext5Pm2WnOL1Meta?.alias || [],
    redirect: indext5Pm2WnOL1Meta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginWLisoaJ9nMMeta?.name ?? "login",
    path: loginWLisoaJ9nMMeta?.path ?? "/login",
    meta: loginWLisoaJ9nMMeta || {},
    alias: loginWLisoaJ9nMMeta?.alias || [],
    redirect: loginWLisoaJ9nMMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playT4uVvXgVeHMeta?.name ?? "play",
    path: playT4uVvXgVeHMeta?.path ?? "/play",
    meta: playT4uVvXgVeHMeta || {},
    alias: playT4uVvXgVeHMeta?.alias || [],
    redirect: playT4uVvXgVeHMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handleFG7J2jHLz2Meta?.name ?? "settings-handle",
    path: handleFG7J2jHLz2Meta?.path ?? "/settings/handle",
    meta: handleFG7J2jHLz2Meta || {},
    alias: handleFG7J2jHLz2Meta?.alias || [],
    redirect: handleFG7J2jHLz2Meta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: index9Cm5iZpJ6nMeta?.name ?? "settings",
    path: index9Cm5iZpJ6nMeta?.path ?? "/settings",
    meta: index9Cm5iZpJ6nMeta || {},
    alias: index9Cm5iZpJ6nMeta?.alias || [],
    redirect: index9Cm5iZpJ6nMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upn7Hzt22HeEMeta?.name ?? "sign-up",
    path: sign_45upn7Hzt22HeEMeta?.path ?? "/sign-up",
    meta: sign_45upn7Hzt22HeEMeta || {},
    alias: sign_45upn7Hzt22HeEMeta?.alias || [],
    redirect: sign_45upn7Hzt22HeEMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statisticVxWuSB3L46Meta?.name ?? "statistic",
    path: statisticVxWuSB3L46Meta?.path ?? "/statistic",
    meta: statisticVxWuSB3L46Meta || {},
    alias: statisticVxWuSB3L46Meta?.alias || [],
    redirect: statisticVxWuSB3L46Meta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournamentEb0zpXjh4cMeta?.name ?? "tournament",
    path: tournamentEb0zpXjh4cMeta?.path ?? "/tournament",
    meta: tournamentEb0zpXjh4cMeta || {},
    alias: tournamentEb0zpXjh4cMeta?.alias || [],
    redirect: tournamentEb0zpXjh4cMeta?.redirect,
    component: () => import("/tmp/build_a488a427/pages/tournament.vue").then(m => m.default || m)
  }
]