import { STORE_KEY } from '@/stores/statistic/constants';
import { useWinsPerLevelStore } from '@/stores/statistic/winsPerLevel';
import { useLevelProgressStore } from '@/stores/statistic/levelProgress';
import { useTrumpsWinsStore } from '@/stores/statistic/trumpsWins';

export const useStatisticStore = defineStore(STORE_KEY, () => {
  const winsPerLevelStore = useWinsPerLevelStore();
  const levelProgressStore = useLevelProgressStore();
  const trumpsWinsStore = useTrumpsWinsStore();

  async function getStatisticPageData() {
    return await Promise.all([
      winsPerLevelStore.getPageData(),
      levelProgressStore.getPageData(),
      trumpsWinsStore.getPageData()
    ]);
  }

  function resetStore() {
    winsPerLevelStore.resetStore();
    levelProgressStore.resetStore();
    trumpsWinsStore.resetStore();
  }

  return {
    getStatisticPageData,
    resetStore
  };
});
